
import QRCode from '~/components/PaymentMethod/QRCodeCollapse.vue'
import QRCS from '~/components/PaymentMethod/QRCSCollapse.vue'
export default {
  data () {
    return {
      selectedPaymentMethod: null,
      collapses: {
        qrcode: false,
        qrcs: false,
        scb: false
      }
    }
  },
  watch: {
    selectedPaymentMethod (newValue, oldValue) {
      Object.keys(this.collapses).forEach((key) => {
        this.collapses[key] = false
      })

      // Set the selected collapse state to true
      this.collapses[newValue.toLowerCase()] = true
    }
  },
  components: {
    QRCode,
    QRCS
  }
}
