
export default {
  data () {
    return {
      order: null,
      orderId: 3698
    }
  },
  methods: {
    async saveImage () {
      console.log('*** save image ***')
      try {
        const response = await fetch(this.base64Image)
        const blob = await response.blob()

        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'image.jpg' // Specify the filename here
        link.click()

        window.URL.revokeObjectURL(link.href)
      } catch (error) {
        console.error('Error saving image:', error)
      }
    }
  },
  mounted () {
    console.log('orderrrr >>> ', this.order)
  },
  computed: {
    base64Image () {
      if (this.order.api_payment_response.qrImage) {
        return 'data:image/png;base64,' + this.order.api_payment_response.qrImage
      }
      return null
    }
  },
  async asyncData ({ params, $axios, $cookies, redirect }) {
    try {
      const { data } = await $axios.$get(`orders/${params.id}`)
      return {
        order: data
      }
    } catch (e) {
      console.log('error', e)
      redirect('/')
    }
  }
}
