import { render, staticRenderFns } from "./test-payment.vue?vue&type=template&id=5066944b&scoped=true"
import script from "./test-payment.vue?vue&type=script&lang=js"
export * from "./test-payment.vue?vue&type=script&lang=js"
import style0 from "./test-payment.vue?vue&type=style&index=0&id=5066944b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5066944b",
  null
  
)

export default component.exports